// https://vuex.vuejs.org/en/actions.html

import router from '@/router';
import { snapshotToArray, snapshotToArraySingle, snapshotToList } from '@/common';
import { firebaseConfig } from '../firebase';
import { getUnixTime } from 'date-fns';

import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/functions';

export default {
    userLogin({ commit, dispatch }, { email, password }) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(user => {
                commit('setUserId', user.user.uid);
                firebase
                    .database()
                    .ref('users_pub/' + user.user.uid)
                    .once('value', snapshot => {
                        commit('setSubscriberId', snapshot.val());
                        var subId = snapshot.val();
                        firebase
                            .database()
                            .ref('subscribers/' + subId + '/users/' + user.user.uid)
                            .once('value', snapshot => {
                                commit('setUserDetails', {
                                    key: user.user.uid,
                                    firstName: snapshot.val().firstName,
                                    lastName: snapshot.val().lastName,
                                    email: snapshot.val().email,
                                    permissions: snapshot.val().permissions,
                                    permissionsMap:
                                        typeof snapshot.val().permissionsMap === 'number'
                                            ? snapshot.val().permissionsMap
                                            : 0x00,
                                    viewPermissionsMap:
                                        typeof snapshot.val().viewPermissionsMap === 'number'
                                            ? snapshot.val().viewPermissionsMap
                                            : 0x00
                                });

                                firebase
                                    .database()
                                    .ref('subscribers/' + subId + '/general')
                                    .once('value', snapshot => {
                                        commit('setGeneral', snapshot.val());

                                        firebase
                                            .database()
                                            .ref('subscribers/' + subId + '/subscription')
                                            .once('value', snapshot => {
                                                commit('setSubscription', snapshot.val());
                                                commit('setActivePage', 'Dashboard');
                                                dispatch('getUsersSubs');
                                                dispatch('getTickets');
                                                dispatch('getContracts');
                                                dispatch('getChemicalTickets');
                                                dispatch('getUsers');
                                                dispatch('getTimesheetDetails');
                                                dispatch('getTimesheets');
                                                dispatch('getCultivars');
                                                dispatch('getFarms');
                                                dispatch('getEquipment');
                                                dispatch('getChemicals');
                                                dispatch('getFertiliserTickets');
                                                dispatch('getFertilisers');
                                                dispatch('getRainGauges');
                                                dispatch('getRainRecords');
                                                dispatch('getGrainSettings');
                                                commit('setFirebaseWorking', false);
                                                router.push('/home');
                                            });
                                    });
                            });
                    });
            })
            .catch(err => {
                dispatch('clearData');
                alert(err.message);
            });
    },
    clearData({ commit }) {
        /* General */
        commit('setFirebaseWorking', false);
        commit('setUserId', null);
        commit('setSubscriberId', null);
        commit('setSubscription', null);
        commit('setUsersSubs', null);
        commit('setGeneral', null);
        commit('setGrainSettings', null);
        commit('setTimesheetSettings', null);
        commit('setUserDetails', null);

        /* Assets */
        commit('setFarms', null);
        commit('setPaddockMaps', null);
        commit('setChemicalDepots', null);
        commit('setWaterStorages', null);
        commit('setRainGauges', null);
        commit('setRainRecords', null);
        commit('setCultivars', null);
        commit('setLivestock', null);
        commit('setVetProducts', null);
        commit('setEquipment', null);
        commit('setChemicals', null);
        commit('setFertilisers', null);
        commit('setUsers', null);
        commit('setNozzles', null);
        commit('setWaterLicences', null);
        commit('setChecklistTemplates', null);
        commit('setInductionTemplates', null);
        commit('setCostCodes', null);
        commit('setCalendarItems', null);

        /* Activities */
        commit('setJobs', null);
        commit('setPumpReadings', null);
        commit('setEquipmentInspections', null);
        commit('setPurchaseOrders', null);
        commit('setSprayLogs', null);
        commit('setPlantings', null);
        commit('setSpreadings', null);
        commit('setHarvests', null);
        commit('setMaintenance', null);
        commit('setIrrigations', null);
        commit('setGrazings', null);
        commit('setVetTreatments', null);

        /* Inventory */
        commit('setTickets', null);
        commit('setFuelTickets', null);
        commit('setChemicalTickets', null);
        commit('setFertiliserTickets', null);
        commit('setWaterTickets', null);
        commit('setContracts', null);

        /* Personnel */
        commit('setTimesheets', null);
        commit('setTimesheetDetails', null);
        commit('setNewTimesheets', null);
        commit('setTimesheetSubmissions', null);
        commit('setWorkTypes', null);
        commit('setInductions', null);
        commit('setQualifications', null);
        commit('setToolboxMeetings', null);
    },
    userSignOut({ commit, dispatch }) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch('clearData');
                router.push('/');
            })
            .catch(() => {
                dispatch('clearData');
                router.push('/');
            });
    },
    userResetPassword({ commit }, email) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                alert('A password reset email has been sent to ' + email);
                commit('setFirebaseWorking', false);
            })
            .catch(err => {
                alert(err.message);
                commit('setFirebaseWorking', false);
            });
    },
    userSetPassword({ commit }, email) {
        commit('setFirebaseWorking', true);
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                alert('A set password email has been sent to ' + email);
                commit('setFirebaseWorking', false);
            })
            .catch(err => {
                alert(err.message);
                commit('setFirebaseWorking', false);
            });
    },
    userCreation({ commit, dispatch }, { firstName, lastName, subName, email, phoneNumber, discount }) {
        commit('setFirebaseWorking', true);
        // Generate Random Password
        let password = firebase.database().ref('users_pub').push().key;

        // Create new user
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(user => {
                // Create new sub ID
                let subRef = firebase.database().ref('subscribers').push().key;

                // Create Function call
                var addMessage = firebase.functions().httpsCallable('setupNewWebSubscriber');

                // Call setup new subscriber function
                addMessage({ subscriber_id: subRef, discount_code: discount})
                    .then(result => {
                        // Check result is OK
                        if (result.data.status != 0) {
                            commit('setFirebaseWorking', false);
                            alert('Unknown Error');
                            return;
                        }

                        // Construct default general details
                        let payload = { name: subName, paddockUnits: 0, fuelUnits: 0, siloUnits: 0, units: 0 };

                        // Create new general details
                        firebase
                            .database()
                            .ref('subscribers/' + subRef + '/general')
                            .update(payload, error => {
                                // Callback for error or success
                                if (error) {
                                    alert(error.message);
                                    commit('setFirebaseWorking', false);
                                } else {
                                    // Create user details
                                    let userUpdates = {
                                        firstName: firstName,
                                        lastName: lastName,
                                        email: email,
                                        permissions: 170,
                                        phoneNumber: phoneNumber
                                    };

                                    // Add new user details in the sub
                                    firebase
                                        .database()
                                        .ref('subscribers/' + subRef + '/users')
                                        .child(user.user.uid)
                                        .update(userUpdates, error => {
                                            // Callback for error or success
                                            if (error) {
                                                alert(error.message);
                                                commit('setFirebaseWorking', false);
                                            } else {
                                                // Add user with link to sub in users_pub
                                                firebase
                                                    .database()
                                                    .ref('users_pub')
                                                    .child(user.user.uid)
                                                    .set(subRef, error => {
                                                        // Callback for error or success
                                                        if (error) {
                                                            alert(error.message);
                                                            commit('setFirebaseWorking', false);
                                                        } else {
                                                            // Init name to email
                                                            var ownerName = email;

                                                            // First Name or Last Name?
                                                            if (firstName != '' || lastName != '') {
                                                                ownerName = firstName + ' ' + lastName;
                                                            }

                                                            // Date now
                                                            let dateNow = getUnixTime(Date());

                                                            // Create one Job
                                                            let job = {
                                                                dueDate: 0.0,
                                                                description: 'Demo',
                                                                priority: 1,
                                                                category: 0,
                                                                ownerId: user.user.uid,
                                                                ownerName: ownerName,
                                                                assignedId: '',
                                                                assignedName: '',
                                                                status: 0,
                                                                startedDate: 0.0,
                                                                completedDate: 0.0,
                                                                statusUpdatedDate: dateNow,
                                                                statusUpdatedUserName: ownerName,
                                                                lastUpdatedDate: dateNow
                                                            };

                                                            var newKey = firebase
                                                                .database()
                                                                .ref('/subscribers/' + subRef + '/jobs')
                                                                .push().key;

                                                            firebase
                                                                .database()
                                                                .ref('subscribers/' + subRef + '/jobs')
                                                                .child(newKey)
                                                                .update(job, error => {
                                                                    // Callback for error or success
                                                                    if (error) {
                                                                        alert(error.message);
                                                                        commit('setFirebaseWorking', false);
                                                                    } else {
                                                                        // Create on Spray Log
                                                                        let sprayLog = {
                                                                            operatorId: '',
                                                                            operatorName: '',
                                                                            dueDate: 0.0,
                                                                            crop: 'Demo',
                                                                            equipmentId: '',
                                                                            nozzle: '',
                                                                            totalWaterInTank: -1,
                                                                            tankUnits: 0,
                                                                            waterRate: -1,
                                                                            waterRateUnits: 1,
                                                                            notes: '',
                                                                            status: 0,
                                                                            startedDate: 0.0,
                                                                            completedDate: 0.0,
                                                                            statusUpdateDate: dateNow,
                                                                            statusUpdatedUserName: ownerName,
                                                                            lastUpdatedDate: dateNow
                                                                        };

                                                                        newKey = firebase
                                                                            .database()
                                                                            .ref('subscribers/' + subRef + '/sprayLogs')
                                                                            .push().key;

                                                                        firebase
                                                                            .database()
                                                                            .ref('subscribers/' + subRef + '/sprayLogs')
                                                                            .child(newKey)
                                                                            .update(sprayLog, error => {
                                                                                if (error) {
                                                                                    alert(error.message);
                                                                                    commit('setFirebaseWorking', false);
                                                                                } else {
                                                                                    // Setup Complete
                                                                                    commit('setNewSubFlag', true);
                                                                                    dispatch('userLogin', {
                                                                                        email: email,
                                                                                        password: password
                                                                                    });
                                                                                    dispatch(
                                                                                        'userSetPassword',
                                                                                        email
                                                                                    );
                                                                                }
                                                                            });
                                                                    }
                                                                });
                                                        }
                                                    });
                                            }
                                        });
                                }
                            });
                    })
                    .catch(error => {
                        // Getting the Error details.
                        alert(error.message);
                        commit('setFirebaseWorking', false);
                    });
            })
            .catch(err => {
                alert(err.message);
                commit('setFirebaseWorking', false);
            });
    },
    getUsersSubs({ state, commit }) {
        firebase
            .database()
            .ref('users_subs/' + state.userId)
            .once('value', snapshot => {
                commit('setUsersSubs', snapshotToList(snapshot.val()));
            });
    },
    switchUserSub({ commit, dispatch }, { subToChangeTo }) {
        commit('setFirebaseWorking', true);

        // Create Function call
        var addMessage = firebase.functions().httpsCallable('switchSub');

        // Call switch sub
        addMessage({ subscriber_id: subToChangeTo })
            .then(result => {
                console.log(result);
                dispatch('userSignOut');
                commit('setFirebaseWorking', false);
            })
            .catch(err => {
                alert(err.message);
                commit('setFirebaseWorking', false);
            });
    },
    clearNewSub({ commit }) {
        commit('setNewSubFlag', false);
    },
    getGrainSettings({ state, commit }) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grainSettings')
            .once('value', snapshot => {
                commit('setGrainSettings', snapshot.val());
            });
    },
    getTimesheetSettings({ state, commit }) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/timesheetSettings')
            .once('value', snapshot => {
                commit('setTimesheetSettings', snapshot.val());
            });
    },
    /**
     * JOBS
     */
    getJobs({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/jobs')
            .once('value', snapshot => {
                commit('setJobs', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addJob({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/jobs')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/jobs')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addJob', payload);
                    alert('Job Added');
                }
            });
    },
    updateJob({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/jobs')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateJob', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteJob({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/jobs')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeJob', payload.index);
                    alert('Job Removed');
                }
            });
    },
    /**
     * EQUIPMENT INSPECTIONS
     */
     getEquipmentInspections({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/equipmentInspections')
            .once('value', snapshot => {
                commit('setEquipmentInspections', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * PUMP READINGS
     */
     getPumpReadings({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/pumpReadings')
            .once('value', snapshot => {
                commit('setPumpReadings', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * PURCHASE ORDERS
     */
    getPurchaseOrders({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/purchaseOrders')
            .once('value', snapshot => {
                commit('setPurchaseOrders', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    updatePurchaseOrder({ state, commit }, payload) {
        if (payload.key != '') {
            firebase
                .database()
                .ref('subscribers/' + state.subscriberId + '/purchaseOrders')
                .child(payload.key)
                .update(payload.item, error => {
                    // Callback for error or success
                    if (error) {
                        alert(error.message);
                    } else {
                        payload.item['key'] = payload.key;
                        commit('updatePurchaseOrder', {
                            index: payload.index,
                            item: payload.item
                        });
                    }
                });
        }
    },
    deletePurchaseOrder({ state, commit }, payload) {
        if (payload.key != '') {
            firebase
                .database()
                .ref('subscribers/' + state.subscriberId + '/purchaseOrders')
                .child(payload.key)
                .remove(error => {
                    // Callback for error or success
                    if (error) {
                        alert(error.message);
                    } else {
                        commit('removePurchaseOrder', payload.index);
                        alert('Purchase Order Removed');
                    }
                });
        }
    },
    /**
     * SPRAY LOGS
     */
    getSprayLogs({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/sprayLogs')
            .once('value', snapshot => {
                commit('setSprayLogs', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addSprayLog({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/sprayLogs')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('addSprayLog', payload.item);
                    alert('Spray Log Added');
                }
            });
    },
    updateSprayLog({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/sprayLogs')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateSprayLog', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteSprayLog({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/sprayLogs')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeSprayLog', payload.index);
                    alert('Spray Log Removed');
                }
            });
    },
    /**
     * PLANTINGS
     */
    getPlantings({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/planting')
            .once('value', snapshot => {
                commit('setPlantings', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addPlanting({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/planting')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/planting')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addPlanting', payload);
                    alert('Planting Record Added');
                }
            });
    },
    updatePlanting({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/planting')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updatePlanting', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deletePlanting({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/planting')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removePlanting', payload.index);
                    alert('Planting Record Removed');
                }
            });
    },
    /**
     * SPREADINGS
     */
    getSpreadings({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilising')
            .once('value', snapshot => {
                commit('setSpreadings', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addSpreading({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/fertilising')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilising')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addSpreading', payload);
                    alert('Spreading Record Added');
                }
            });
    },
    updateSpreading({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilising')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateSpreading', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteSpreading({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilising')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeSpreading', payload.index);
                    alert('Spreading Record Removed');
                }
            });
    },
    /**
     * HARVESTS
     */
    getHarvests({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/harvest')
            .once('value', snapshot => {
                commit('setHarvests', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addHarvest({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/harvest')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/harvest')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addHarvest', payload);
                    alert('Harvest Record Added');
                }
            });
    },
    updateHarvest({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/harvest')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateHarvest', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteHarvest({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/harvest')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeHarvest', payload.index);
                    alert('Harvest Record Removed');
                }
            });
    },
    /**
     * MAINTENANCE
     */
    getMaintenance({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaintenance')
            .once('value', snapshot => {
                commit('setMaintenance', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addMaintenance({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/paddockMaintenance')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaintenance')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addMaintenance', payload);
                    alert('Paddock Maintenance Record Added');
                }
            });
    },
    updateMaintenance({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaintenance')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateMaintenance', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteMaintenance({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaintenance')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeMaintenance', payload.index);
                    alert('Paddock Maintenance Record Removed');
                }
            });
    },
    /**
     * IRRIGATION
     */
    getIrrigations({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/irrigation')
            .once('value', snapshot => {
                commit('setIrrigations', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addIrrigation({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/irrigation')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/irrigation')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addIrrigation', payload);
                    alert('Irrigation Record Added');
                }
            });
    },
    updateIrrigation({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/irrigation')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateIrrigation', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteIrrigation({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/irrigation')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeIrrigation', payload.index);
                    alert('Irrigation Record Removed');
                }
            });
    },
    /**
     * SOIL TESTS
     */
    getSoilTests({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/soilTests')
            .once('value', snapshot => {
                commit('setSoilTests', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addSoilTest({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/soilTests')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/soilTests')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addSoilTest', payload);
                }
            });
    },
    updateSoilTest({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/soilTests')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateSoilTest', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteSoilTest({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/soilTests')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeSoilTest', payload.index);
                    alert('Soil Test Removed');
                }
            });
    },
    /**
     * GRAZINGS
     */
    getGrazings({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grazing')
            .once('value', snapshot => {
                commit('setGrazings', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addGrazing({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/grazing')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grazing')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addGrazing', payload);
                    alert('Grazing Record Added');
                }
            });
    },
    updateGrazing({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grazing')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateGrazing', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteGrazing({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/grazing')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeGrazing', payload.index);
                    alert('Grazing Record Removed');
                }
            });
    },
    /**
     * VET TREATMENTS
     */
     getVetTreatments({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetTreatments')
            .once('value', snapshot => {
                commit('setVetTreatments', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addVetTreatment({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/vetTreatments')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetTreatments')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addVetTreatment', payload);
                    alert('Vet Treatment Record Added');
                }
            });
    },
    updateVetTreatment({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetTreatments')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateVetTreatment', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteVetTreatment({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetTreatments')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeVetTreatment', payload.index);
                    alert('Vet Treatment Record Removed');
                }
            });
    },
    /**
     * GRAIN TICKETS
     */
    getTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/tickets')
            .once('value', snapshot => {
                commit('setTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * FUEL TICKETS
     */
    getFuelTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fuelTickets')
            .once('value', snapshot => {
                commit('setFuelTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addFuelTicket({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/fuelTickets')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fuelTickets')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addFuelTicket', payload);
                    alert('Fuel Ticket Added');
                }
            });
    },
    updateFuelTicket({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fuelTickets')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateFuelTicket', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    /**
     * CHEMICAL TICKETS
     */
    getChemicalTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalTickets')
            .once('value', snapshot => {
                commit('setChemicalTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addChemicalTicket({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/chemicalTickets')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalTickets')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addChemicalTicket', payload);
                    alert('Chemical Ticket Added')
                }
            });
    },
    updateChemicalTicket({ state }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalTickets')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    /*payload.item['key'] = payload.key;
                    commit('updateChemicalTicket', {
                        index: payload.index,
                        item: payload.item
                    });*/
                }
            });
    },
    /**
     * FERTILISER TICKETS
     */
    getFertiliserTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertiliserTickets')
            .once('value', snapshot => {
                commit('setFertiliserTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addFertiliserTicket({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/fertiliserTickets')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertiliserTickets')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addFertiliserTicket', payload);
                    alert('Fertiliser Ticket Added');
                }
            });
    },
    updateFertiliserTicket({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertiliserTickets')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateFertiliserTicket', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    /**
     * WATER TICKETS
     */
    getWaterTickets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterTickets')
            .once('value', snapshot => {
                commit('setWaterTickets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * GRAIN CONTRACTS
     */
    getContracts({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contracts')
            .once('value', snapshot => {
                commit('setContracts', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * TIMESHEETS
     */
    getTimesheets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/timesheets')
            .once('value', snapshot => {
                commit('setTimesheets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    getTimesheetDetails({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/timesheetDetails')
            .once('value', snapshot => {
                commit('setTimesheetDetails', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    getNewTimesheets({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/newTimesheets')
            .once('value', snapshot => {
                commit('setNewTimesheets', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    getTimesheetSubmissions({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/timesheetSubmissions')
            .once('value', snapshot => {
                commit('setTimesheetSubmissions', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * WORK TYPES
     */
    getWorkTypes({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/workTypes')
            .once('value', snapshot => {
                commit('setWorkTypes', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addWorkType({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/workTypes')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/workTypes')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addWorkType', payload);
                    alert('Work Type Added');
                }
            });
    },
    updateWorkType({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/workTypes')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateWorkType', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteWorkType({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/workTypes')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeWorkType', payload.index);
                    alert('Work Type Removed');
                }
            });
    },
    /**
     * CHECKLIST TEMPLATES
     */
    getChecklistTemplates({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/checklistTemplates')
            .once('value', snapshot => {
                commit('setChecklistTemplates', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addChecklistTemplate({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/checklistTemplates')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/checklistTemplates')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addChecklistTemplate', payload);
                    alert('Checklist Template Added');
                }
            });
    },
    updateChecklistTemplate({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/checklistTemplates')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateChecklistTemplate', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteChecklistTemplate({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/checklistTemplates')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeChecklistTemplate', payload.index);
                    alert('Checklist Template Removed');
                }
            });
    },
    /**
     * INDUCTION TEMPLATES
     */
    getInductionTemplates({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/inductionTemplates')
            .once('value', snapshot => {
                commit('setInductionTemplates', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addInductionTemplate({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/inductionTemplates')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/inductionTemplates')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addInductionTemplate', payload);
                    alert('Induction Template Added');
                }
            });
    },
    updateInductionTemplate({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/inductionTemplates')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateInductionTemplate', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteInductionTemplate({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/inductionTemplates')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeInductionTemplate', payload.index);
                    alert('Induction Template Removed');
                }
            });
    },
    /**
     * INDUCTIONS
     */
    getInductions({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/inductions')
            .once('value', snapshot => {
                commit('setInductions', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * QUALIFICATIONS
     */
    getQualifications({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/qualifications')
            .once('value', snapshot => {
                commit('setQualifications', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addQualification({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/qualifications')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/qualifications')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addQualification', payload);
                    alert('Qualification Added');
                }
            });
    },
    updateQualification({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/qualifications')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateQualification', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteQualification({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/qualifications')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeQualification', payload.index);
                    alert('Qualification Removed');
                }
            });
    },
    /**
     * TOOLBOX MEETINGS
     */
    getToolboxMeetings({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/toolboxMeetings')
            .once('value', snapshot => {
                commit('setToolboxMeetings', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * FARMS
     */
    getFarms({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .once('value', snapshot => {
                var tempFarmList = snapshotToArraySingle(snapshot.val());
                var paddockArray = [];
                var fuelTanksArray = [];
                var silosArray = [];

                for (let index in tempFarmList) {
                    paddockArray.push(snapshotToArray(snapshot.child(tempFarmList[index].key).child('paddocks').val()));
                    fuelTanksArray.push(
                        snapshotToArray(snapshot.child(tempFarmList[index].key).child('fuelTanks').val())
                    );
                    silosArray.push(snapshotToArray(snapshot.child(tempFarmList[index].key).child('silos').val()));
                }

                commit('setFarms', {
                    farmList: tempFarmList,
                    paddocks: paddockArray,
                    fuelTanks: fuelTanksArray,
                    silos: silosArray
                });
                commit('setFirebaseWorking', false);
            });
    },
    addFarm({ state, commit }, payload) {
        /* Payload: name */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/farms')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addFarm', payload);
                    alert('Farm Added');
                }
            });
    },
    updateFarm({ state, commit }, payload) {
        /* Payload: index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.index].key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = state.farms.farmList[payload.index].key;
                    payload.item['index'] = payload.index;
                    commit('updateFarm', payload.item);
                }
            });
    },
    deleteFarm({ state, dispatch }, payload) {
        /* Payload: index */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload].key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    dispatch('getFarms');
                    alert('Farm Removed');
                }
            });
    },
    addFarmPaddock({ state, commit }, payload) {
        /* Payload: farm
         *          item
         */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('paddocks')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('paddocks')
            .child(newKey)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = newKey;
                    commit('addFarmPaddock', {
                        farm: payload.farm,
                        item: payload.item
                    });
                    alert('Paddock Added');
                }
            });
    },
    updateFarmPaddock({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('paddocks')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateFarmPaddock', {
                        farm: payload.farm,
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteFarmPaddock({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         */
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('paddocks')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeFarmPaddock', {
                        farm: payload.farm,
                        index: payload.index
                    });
                    alert('Paddock Removed');
                }
            });
    },
    addFarmFuelTank({ state, commit }, payload) {
        /* Payload: farm
         *          item
         */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('fuelTanks')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('fuelTanks')
            .child(newKey)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = newKey;
                    commit('addFarmFuelTank', {
                        farm: payload.farm,
                        item: payload.item
                    });
                    alert('Fuel Tank Added');
                }
            });
    },
    updateFarmFuelTank({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('fuelTanks')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateFarmFuelTank', {
                        farm: payload.farm,
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteFarmFuelTank({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         */
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('fuelTanks')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeFarmFuelTank', {
                        farm: payload.farm,
                        index: payload.index
                    });
                    alert('Fuel Tank Removed');
                }
            });
    },
    addFarmSilo({ state, commit }, payload) {
        /* Payload: farm
         *          item
         */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('silos')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('silos')
            .child(newKey)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = newKey;
                    commit('addFarmSilo', {
                        farm: payload.farm,
                        item: payload.item
                    });
                    alert('Silo Added');
                }
            });
    },
    updateFarmSilo({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('silos')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateFarmSilo', {
                        farm: payload.farm,
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteFarmSilo({ state, commit }, payload) {
        /* Payload: farm
         *          key
         *          index
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/farms')
            .child(state.farms.farmList[payload.farm].key)
            .child('silos')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeFarmSilo', {
                        farm: payload.farm,
                        index: payload.index
                    });
                    alert('Silo Removed');
                }
            });
    },
    /**
     * Paddock Maps
     */
    getPaddockMaps({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaps')
            .once('value', snapshot => {
                commit('setPaddockMaps', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addPaddockMap({ state, commit }, payload) {
        /* Payload: key
         *          item
         */

        // Commit new Paddock Map object
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaps')
            .child(payload.key)
            .set(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('addPaddockMap', payload.item);
                    alert('Paddock Map Added');
                }
            });
    },
    updatePaddockMap({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaps')
            .child(payload.key)
            .set(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updatePaddockMap', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deletePaddockMap({ state, commit }, payload) {
        /* Payload: index
         *          key
         */
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/paddockMaps')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removePaddockMap', payload.index);
                    alert('Paddock Map Removed');
                }
            });
    },
    getChemicalDepots({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalDepots')
            .once('value', snapshot => {
                commit('setChemicalDepots', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addChemicalDepot({ state, commit }, payload) {
        /* Payload: chemical depot only */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/chemicalDepots')
            .push().key;

        // Commit new Chemical Depot object
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalDepots')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addChemicalDepot', payload);
                    alert('Chemical Depot Added');
                }
            });
    },
    updateChemicalDepot({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalDepots')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateChemicalDepot', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteChemicalDepot({ state, commit }, payload) {
        /* Payload: index
         *          key
         */
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicalDepots')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeChemicalDepot', payload.index);
                    alert('Chemical Depot Removed');
                }
            });
    },
    getWaterStorages({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterStorage')
            .once('value', snapshot => {
                commit('setWaterStorages', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addWaterStorage({ state, commit }, payload) {
        /* Payload: water storage only */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/waterStorage')
            .push().key;

        // Commit new Water Storage object
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterStorage')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addWaterStorage', payload);
                    alert('Water Storage Added');
                }
            });
    },
    updateWaterStorage({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterStorage')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateWaterStorage', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteWaterStorage({ state, commit }, payload) {
        /* Payload: index
         *          key
         */
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterStorage')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeWaterStorage', payload.index);
                    alert('Water Storage Removed');
                }
            });
    },
    /**
     * RAIN
     */
    getRainGauges({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainGauges')
            .once('value', snapshot => {
                commit('setRainGauges', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addRainGauge({ state, commit }, payload) {
        /* Payload: rain gauge only */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/rainGauges')
            .push().key;

        // Commit new Rain Gauge object
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainGauges')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addRainGauge', payload);
                    alert('Rain Gauge Added');
                }
            });
    },
    updateRainGauge({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainGauges')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateRainGauge', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteRainGauge({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainGauges')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeRainGauge', payload.index);
                    alert('Rain Gauge Removed');
                }
            });
    },
    getRainRecords({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainRecords')
            .once('value', snapshot => {
                commit('setRainRecords', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addRainRecord({ state, commit }, payload) {
        /* Payload: rain record only */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/rainRecords')
            .push().key;

        // Commit new Rain Record object
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainRecords')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addRainRecord', payload);
                    alert('Rainfall Record Added');
                }
            });
    },
    updateRainRecord({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainRecords')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateRainRecord', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteRainRecord({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/rainRecords')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeRainRecord', payload.index);
                    alert('Rainfall Record Removed');
                }
            });
    },
    /**
     * CULTIVARS
     */
    getCultivars({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/cultivars')
            .once('value', snapshot => {
                commit('setCultivars', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addCultivar({ state, commit }, payload) {
        /* Payload: cultivar only */

        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/cultivars')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/cultivars')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addCultivar', payload);
                    alert('Cultivar Added');
                }
            });
    },
    updateCultivar({ state, commit }, payload) {
        /* Payload: key
         *          index
         *          item
         */

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/cultivars')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateCultivar', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteCultivar({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/cultivars')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeCultivar', payload.index);
                    alert('Cultivar Removed');
                }
            });
    },
    /** 
     * LIVESTOCK 
     */
    getLivestock({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/livestock')
            .once('value', snapshot => {
                commit('setLivestock', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addLivestock({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/livestock')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/livestock')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addLivestock', payload);
                    alert('Livestock Added');
                }
            });
    },
    updateLivestock({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/livestock')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateLivestock', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteLivestock({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/livestock')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeLivestock', payload.index);
                    alert('Livestock Removed');
                }
            });
    },
    /** 
     * VET PRODUCTS 
     */
     getVetProducts({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetProducts')
            .once('value', snapshot => {
                commit('setVetProducts', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addVetProduct({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/vetProducts')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetProducts')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addVetProduct', payload);
                    alert('Vet Product Added');
                }
            });
    },
    updateVetProduct({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetProducts')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateVetProduct', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteVetProduct({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/vetProducts')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeVetProduct', payload.index);
                    alert('Vet Product Removed');
                }
            });
    },
    /** 
     * EQUIPMENT 
     */
    getEquipment({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/equipment')
            .once('value', snapshot => {
                commit('setEquipment', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addEquipment({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/equipment')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/equipment')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addEquipment', payload);
                    alert('Equipment Added');
                }
            });
    },
    updateEquipment({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/equipment')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateEquipment', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteEquipment({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/equipment')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeEquipment', payload.index);
                    alert('Equipment Removed');
                }
            });
    },
    /**
     * CHEMICALS
     */
    getChemicals({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicals')
            .once('value', snapshot => {
                commit('setChemicals', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addChemical({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/chemicals')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicals')
            .child(newKey)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    // Set key
                    payload.item['key'] = newKey;

                    // Different inventory?
                    if (payload.createTicket == true) {
                        // Update
                        payload.item['inventory'] = payload.inventory;

                        // Load values
                        var chems = new Object();
                        chems[newKey] = payload.inventory;
                        var reported = new Object();
                        reported[newKey] = payload.inventory;

                        // Create ticket
                        var ticket = {
                            type: 2,
                            date: getUnixTime(Date()),
                            operatorId: state.userId,
                            operatorName: state.userDetails.firstName + ' ' + state.userDetails.lastName,
                            chemicals: chems,
                            reportedTotals: reported
                        };

                        // Create blank item and get generated key
                        var tickNewKey = firebase
                            .database()
                            .ref('/subscribers/' + state.subscriberId + '/chemicalTickets')
                            .push().key;

                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/chemicalTickets')
                            .child(tickNewKey)
                            .update(ticket, error => {
                                if (error) {
                                    alert(error.message);
                                }
                            });
                    }

                    // Commit
                    commit('addChemical', payload.item);
                    alert('Chemical Added');
                }
            });
    },
    updateChemical({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicals')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    // Set key
                    payload.item['key'] = payload.key;

                    // Different inventory?
                    if (payload.createTicket == true) {
                        // Get difference
                        var difference = payload.inventory - payload.item['inventory'];

                        // Update
                        payload.item['inventory'] = payload.inventory;

                        // Load values
                        var chems = new Object();
                        chems[payload.key] = difference;
                        var reported = new Object();
                        reported[payload.key] = payload.inventory;

                        // Create ticket
                        var ticket = {
                            type: 2,
                            date: getUnixTime(Date()),
                            operatorId: state.userId,
                            operatorName: state.userDetails.firstName + ' ' + state.userDetails.lastName,
                            chemicals: chems,
                            reportedTotals: reported
                        };

                        // Create blank item and get generated key
                        var newKey = firebase
                            .database()
                            .ref('/subscribers/' + state.subscriberId + '/chemicalTickets')
                            .push().key;

                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/chemicalTickets')
                            .child(newKey)
                            .update(ticket, error => {
                                if (error) {
                                    alert(error.message);
                                }
                            });
                    }

                    // Commit
                    commit('updateChemical', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteChemical({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/chemicals')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeChemical', payload.index);
                    alert('Chemical Removed');
                }
            });
    },
    /**
     * FERTILISERS
     */
    getFertilisers({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilisers')
            .once('value', snapshot => {
                commit('setFertilisers', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addFertiliser({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/fertilisers')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilisers')
            .child(newKey)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    // Set key
                    payload.item['key'] = newKey;

                    // Different inventory?
                    if (payload.createTicket == true) {
                        // Update
                        payload.item['inventory'] = payload.inventory;

                        // Load values
                        var ferts = new Object();
                        ferts[newKey] = payload.inventory;
                        var reported = new Object();
                        reported[newKey] = payload.inventory;

                        // Create ticket
                        var ticket = {
                            type: 2,
                            date: getUnixTime(Date()),
                            operatorId: state.userId,
                            operatorName: state.userDetails.firstName + ' ' + state.userDetails.lastName,
                            fertilisers: ferts,
                            reportedTotals: reported
                        };

                        // Create blank item and get generated key
                        var tickNewKey = firebase
                            .database()
                            .ref('/subscribers/' + state.subscriberId + '/fertiliserTickets')
                            .push().key;

                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/fertiliserTickets')
                            .child(tickNewKey)
                            .update(ticket, error => {
                                if (error) {
                                    alert(error.message);
                                }
                            });
                    }

                    // Commit
                    commit('addFertiliser', payload.item);
                    alert('Fertiliser Added');
                }
            });
    },
    updateFertiliser({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilisers')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    // Set key
                    payload.item['key'] = payload.key;

                    // Different inventory?
                    if (payload.createTicket == true) {
                        // Get difference
                        var difference = payload.inventory - payload.item['inventory'];

                        // Update
                        payload.item['inventory'] = payload.inventory;

                        // Load values
                        var ferts = new Object();
                        ferts[payload.key] = difference;
                        var reported = new Object();
                        reported[payload.key] = payload.inventory;

                        // Create ticket
                        var ticket = {
                            type: 2,
                            date: getUnixTime(Date()),
                            operatorId: state.userId,
                            operatorName: state.userDetails.firstName + ' ' + state.userDetails.lastName,
                            fertilisers: ferts,
                            reportedTotals: reported
                        };

                        // Create blank item and get generated key
                        var newKey = firebase
                            .database()
                            .ref('/subscribers/' + state.subscriberId + '/fertiliserTickets')
                            .push().key;

                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/fertiliserTickets')
                            .child(newKey)
                            .update(ticket, error => {
                                if (error) {
                                    alert(error.message);
                                }
                            });
                    }

                    // Commit
                    commit('updateFertiliser', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteFertiliser({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/fertilisers')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeFertiliser', payload.index);
                    alert('Fertiliser Removed');
                }
            });
    },
    /**
     * USERS
     */
    getUsers({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/users')
            .once('value', snapshot => {
                commit('setUsers', {
                    userList: snapshotToArraySingle(snapshot.val()),
                    userDetails: snapshotToArray(snapshot.val())
                });
                commit('setFirebaseWorking', false);
            });
    },
    addUser({ state, dispatch }, payload) {
        // Create a random password
        let password = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // Create secondary app instance to create new user
        var secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');

        // Create new user in secondary app
        secondaryApp
            .auth()
            .createUserWithEmailAndPassword(payload.email, password)
            .catch(error => {
                firebase.app('Secondary').delete();
                alert(error.message);
                return;
            })
            .then(function (firebaseUser) {
                // Sign out of the secondary app
                secondaryApp.auth().signOut();
                firebase.app('Secondary').delete();

                // Create user entry in users_pub
                firebase.database().ref('/users_pub').push(firebaseUser.user.uid);
                firebase.database().ref('/users_pub').child(firebaseUser.user.uid).set(state.subscriberId);

                // Create user entry in subscription
                firebase
                    .database()
                    .ref('/subscribers/' + state.subscriberId + '/users')
                    .push(firebaseUser.uid);
                firebase
                    .database()
                    .ref('subscribers/' + state.subscriberId + '/users')
                    .child(firebaseUser.user.uid)
                    .update(payload, error => {
                        // Callback for error or success
                        if (error) {
                            alert(error.message);
                        } else {
                            dispatch('getUsers');
                            firebase
                                .auth()
                                .sendPasswordResetEmail(payload.email)
                                .then(() => {
                                    alert(
                                        'User Added.\n\nThe user has been sent an email so their password can be set.\n\nThey cannot log in until this has been done'
                                    );
                                })
                                .catch(error => {
                                    alert(error.message);
                                });
                        }
                    });
            })
    },
    updateUserAndEmail({ state, commit }, payload) {
        /* Payload: email,
         *          password,
         *          index,
         *          key,
         *          item
         */

        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(payload.email, payload.password);

        user.reauthenticateWithCredential(credential)
            .then(function () {
                // User re-authenticated
                user.updateEmail(payload.item.email)
                    .then(function () {
                        // Update successful
                        firebase
                            .database()
                            .ref('subscribers/' + state.subscriberId + '/users')
                            .child(payload.key)
                            .update(payload.item, error => {
                                // Callback for error or success
                                if (error) {
                                    alert(error.message);
                                } else {
                                    payload.item['key'] = payload.key;
                                    commit('updateUserAndEmail', {
                                        index: payload.index,
                                        item: payload.item
                                    });
                                    alert('User Details Updated');
                                }
                            });
                    })
                    .catch(function (error) {
                        // An error happened
                        alert(error.message);
                    });
            })
            .catch(function (error) {
                // An error happened
                alert(error.message);
            });
    },
    updateUser({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/users')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateUser', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    setUsersPub({ state }, payload) {
        // Clear users pub?
        if (payload.clear == true) {
            // Clear
            firebase
                .database()
                .ref('users_pub/' + payload.userKey)
                .remove();
        } else {
            // Set
            firebase
                .database()
                .ref('users_pub/' + payload.userKey)
                .set(state.subscriberId)
        }
    },
    userArchive({ state, commit }, { userItem, userIndex }) {
        commit('setFirebaseWorking', true);

        // Create Function call
        var addMessage = firebase.functions().httpsCallable('archiveUserInSub');

        // Call setup new subscriber function
        addMessage({ user_id: userItem.key, subscriber_id: state.subscriberId})
            .then(result => {
                // Check result is OK
                if (result.data.status != 0) {
                    commit('setFirebaseWorking', false);
                    alert('Unknown Error');
                    return;
                }

                // Construct default general details
                commit('updateUser', {
                    index: userIndex,
                    item: userItem
                });
                
                alert('User Archived');
                commit('setFirebaseWorking', false);
            })
            .catch(error => {
                // Getting the Error details.
                alert(error.message);
                commit('setFirebaseWorking', false);
            });
    },
    userRestore({ state, commit }, { userItem, userIndex }) {
        commit('setFirebaseWorking', true);

        // Create Function call
        var addMessage = firebase.functions().httpsCallable('restoreUserInSub');

        // Call setup new subscriber function
        addMessage({ user_id: userItem.key, subscriber_id: state.subscriberId, subscriber_name: state.general.name })
            .then(result => {
                // Check result is OK
                if (result.data.status != 0) {
                    commit('setFirebaseWorking', false);
                    alert('Unknown Error');
                    return;
                }

                // Construct default general details
                commit('updateUser', {
                    index: userIndex,
                    item: userItem
                });

                alert('User Restored');
                commit('setFirebaseWorking', false);
            })
            .catch(error => {
                // Getting the Error details.
                alert(error.message);
                commit('setFirebaseWorking', false);
            });
    },
    /**
     * CONTRACTORS
     */
    getContractors({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contractors')
            .once('value', snapshot => {
                commit('setContractors', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addContractor({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/contractors')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contractors')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addContractor', payload);
                    alert('Contractor Added');
                }
            });
    },
    updateContractor({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contractors')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateContractor', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteContractor({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/contractors')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeContractor', payload.index);
                    alert('Contractor Removed');
                }
            });
    },
    /**
     * NOZZLES
     */
    getNozzles({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/nozzles')
            .once('value', snapshot => {
                commit('setNozzles', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addNozzle({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/nozzles')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/nozzles')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addNozzle', payload);
                    alert('Nozzle Added');
                }
            });
    },
    updateNozzle({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/nozzles')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateNozzle', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteNozzle({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/nozzles')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeNozzle', payload.index);
                    alert('Nozzle Removed');
                }
            });
    },
    /**
     * WATER LICENCES
     */
    getWaterLicences({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterLicences')
            .once('value', snapshot => {
                commit('setWaterLicences', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addWaterLicence({ state, commit }, payload) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/waterLicences')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterLicences')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addWaterLicence', payload);
                    alert('Water Licence Added');
                }
            });
    },
    updateWaterLicence({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterLicences')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateWaterLicence', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteWaterLicence({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/waterLicences')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('removeWaterLicence', payload.index);
                    alert('Water Licence Removed');
                }
            });
    },
    createNewKey({ state, commit }) {
        // Create blank item and get generated key
        var newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId)
            .push().key;

        commit('updateNewKey', newKey);
    },
    /**
     * COST CODES
     */
    getCostCodes({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/costCodes')
            .once('value', snapshot => {
                commit('setCostCodes', snapshotToArray(snapshot.val()));
                commit('addCostCode', { key: '-NONE', code: '', text: 'None' });
                commit('setFirebaseWorking', false);
            });
    },
    addCostCode({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/costCodes')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/costCodes')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addCostCode', payload);
                    alert('Cost Code Added');
                }
            });
    },
    updateCostCode({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/costCodes')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateCostCode', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteCostCode({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/costCodes')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('deleteCostCode', payload.index);
                    alert('Cost Code Removed');
                }
            });
    },
    /**
     * CALENDAR
     */
    getCalendarItems({ state, commit }) {
        commit('setFirebaseWorking', true);
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/calendar')
            .once('value', snapshot => {
                commit('setCalendarItems', snapshotToArray(snapshot.val()));
                commit('setFirebaseWorking', false);
            });
    },
    addCalendarItem({ state, commit }, payload) {
        // Create blank item and get generated key
        let newKey = firebase
            .database()
            .ref('/subscribers/' + state.subscriberId + '/calendar')
            .push().key;

        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/calendar')
            .child(newKey)
            .update(payload, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload['key'] = newKey;
                    commit('addCalendarItem', payload);
                    alert('Calendar Event Added');
                }
            });
    },
    updateCalendarItem({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/calendar')
            .child(payload.key)
            .update(payload.item, error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    payload.item['key'] = payload.key;
                    commit('updateCalendarItem', {
                        index: payload.index,
                        item: payload.item
                    });
                }
            });
    },
    deleteCalendarItem({ state, commit }, payload) {
        firebase
            .database()
            .ref('subscribers/' + state.subscriberId + '/calendar')
            .child(payload.key)
            .remove(error => {
                // Callback for error or success
                if (error) {
                    alert(error.message);
                } else {
                    commit('deleteCalendarItem', payload.index);
                    alert('Calendar Event Removed');
                }
            });
    }
};
