<!--
  //  NewSignup.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 07/11/2019
  //  Copyright © 2019 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <v-container fluid fill-height class="new-signup" >
        <v-row justify="center" align="center" pa-5>
            <v-card width="500px" class="mx-a" raised style="background-color: #dedede">
                <v-card-title class="justify-center">
                    <v-container fill-height fluid>
                        <v-row no-gutters align="center" justify="center">
                            <img width="400px" src="@/assets/logo-colour.svg" />
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <h2 style="margin-bottom: 20px" align="center">New Signup</h2>
                        <v-text-field
                            label="First Name"
                            v-model="firstName"
                            :append-icon="mdiAccount"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            @keyup.enter="$refs.lastName.focus"
                            style="margin: 0px 20px"
                        />
                        <v-text-field
                            ref="lastName"
                            label="Last Name"
                            v-model="lastName"
                            :append-icon="mdiAccount"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            @keyup.enter="$refs.subName.focus"
                            style="margin: 0px 20px"
                        />
                        <v-text-field
                            name="subName"
                            ref="subName"
                            label="Company Name"
                            v-model="subName"
                            :append-icon="mdiLeadPencil"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            :rules="nameRules"
                            required
                            @keyup.enter="$refs.email.focus"
                            style="margin: 0px 20px"
                        />
                        <v-text-field
                            ref="email"
                            label="Email"
                            v-model="email"
                            :append-icon="mdiEmail"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            :rules="emailRules"
                            required
                            @keyup.enter="$refs.phoneNumber.focus"
                            style="margin: 0px 20px"
                        />
                        <v-text-field
                            ref="phoneNumber"
                            label="Phone Number"
                            v-model="phoneNumber"
                            :append-icon="mdiPhone"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            :rules="phoneNumberRules"
                            hint="Optional"
                            @keyup.enter="createSignup"
                            style="margin: 0px 20px"
                        />
                        <!--<v-text-field
                            ref="discount"
                            label="Discount Code"
                            v-model="discount"
                            append-icon="mdiSale"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            :rules="discountRules"
                            @keyup.enter="createSignup"
                            style="margin: 0px 20px"
                        />-->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2 mb-2" outlined color="info" @click="goBack" min-width="100">Cancel</v-btn>
                    <v-spacer />
                    <v-btn
                        class="mr-2 mb-2"
                        @click="createSignup"
                        :loading="isRunning"
                        dark
                        min-width="100"
                        color="#E05317"
                    >
                        Sign Up
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mdiAccount, mdiEmail, mdiPhone, mdiLeadPencil } from '@mdi/js';

export default {
    name: 'NewSignup',
    data: function () {
        return {
            mdiAccount,
            mdiEmail,
            mdiLeadPencil,
            mdiPhone,
            valid: true,
            subName: '',
            nameRules: [v => !!v || 'Name is required'],
            email: '',
            emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
            firstName: '',
            lastName: '',
            phoneNumber: '',
            phoneNumberRules: [v=> v == '' || /^[0-9()-+]+$/.test(v) || 'Phone number must be valid'],
            discount: '',
            discountRules: [v => v == '' || v == 'MCGA2023' || 'Invalid Code']
        };
    },
    computed: {
        isRunning() {
            return this.$store.state.firebaseWorking;
        }
    },
    methods: {
        createSignup: function () {
            if (this.isRunning == false) {
                if (this.$refs.form.validate()) {
                    let vm = this;
                    this.$root
                        .$confirm(
                            'Sign Up',
                            "You are signing up with the email '" +
                                vm.email +
                                "'.  Please check this is correct before continuing - an email will be sent to this account, which you will need to use to set your password",
                            {
                                color: '#E86B24',
                                agree: 'Continue',
                                agreeColour: 'blue darken-2'
                            }
                        )
                        .then(confirm => {
                            if (confirm) {
                                vm.$store.dispatch('userCreation', {
                                    firstName: vm.firstName,
                                    lastName: vm.lastName,
                                    subName: vm.subName,
                                    email: vm.email,
                                    phoneNumber: vm.phoneNumber,
                                    discount: vm.discount
                                });
                            }
                        });
                }
            }
        },
        goBack: function () {
            if (this.isRunning == false) {
                this.$parent.showLogin = 0x5a;
            }
        }
    }
};
</script>

<style scoped>
.new-signup {
    background: url('https://images.unsplash.com/photo-1633554587766-ffc8151e31c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80');
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
