<!--
  //  Front.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 12/08/2019
  //  Copyright © 2019 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <span>
        <login v-if="showLogin == 0x5a"></login>
        <sign-up v-else-if="showLogin == 0xa5"></sign-up>
        <reset-password v-else-if="showLogin == 0xaa"></reset-password>
    </span>
</template>

<script>
import Login from '@/components/Login/Login';
import SignUp from '@/components/Login/NewSignup';
import ResetPassword from '@/components/Login/ResetPassword';

export default {
    name: 'FrontPage',
    components: {
        Login,
        SignUp,
        ResetPassword
    },
    data: function () {
        return {
            showLogin: 0x5a
        };
    }
};
</script>
