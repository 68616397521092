<!--
  //  ResetPassword.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 19/08/2018
  //  Copyright © 2018 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <v-container fluid fill-height class="reset-password" style="max-height: 100vh">
        <v-row justify="center" align="center" pa-5>
            <v-card width="500px" class="mx-a" raised style="background-color: #dedede">
                <v-card-title class="justify-center">
                    <v-container fill-height fluid>
                        <v-row no-gutters align="center" justify="center">
                            <img style="margin-bottom: 20px" width="400px" src="@/assets/logo-colour.svg" />
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <p align="center">Enter your email to request a password reset</p>
                        <v-text-field
                            label="Email"
                            v-model="email"
                            :append-icon="mdiEmail"
                            solo
                            color="#E05317"
                            background-color="#E9E9E9"
                            :rules="emailRules"
                            required
                            autocomplete="username"
                            @keyup.enter="resetPassword"
                            style="margin: 0px 20px"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2 mb-2" outlined color="info" @click="goBack" min-width="100">Back</v-btn>
                    <v-spacer />
                    <v-btn
                        class="mr-2 mb-2"
                        @click="resetPassword"
                        :loading="isRunning"
                        dark
                        min-width="100"
                        color="#E05317"
                        >Reset</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mdiEmail } from '@mdi/js';

export default {
    name: 'ResetPassword',
    data: function () {
        return {
            mdiEmail,
            valid: true,
            email: '',
            emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']
        };
    },
    computed: {
        isRunning() {
            return this.$store.state.firebaseWorking;
        }
    },
    methods: {
        resetPassword: function () {
            if (this.isRunning == false) {
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('userResetPassword', this.email);
                }
            }
        },
        goBack: function () {
            this.$parent.showLogin = 0x5a;
        }
    }
};
</script>

<style scoped>
.reset-password {
    background: url('https://images.unsplash.com/photo-1633554587766-ffc8151e31c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80');
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
