// https://vuex.vuejs.org/en/state.html

export default {
    activePage: 'Dashboard',
    userId: null,
    subscriberId: null,
    subscription: null,
    userDetails: null,
    firebaseWorking: false,
    isNewSub: false,
    loginBusy: false,
    usersSubs: null,
    general: null,
    grainSettings: null,
    timesheetSettings: null,
    newKey: null,
    jobs: [],
    equipmentInspections: [],
    pumpReadings: [],
    purchaseOrders: [],
    sprayLogs: [],
    plantings: [],
    spreadings: [],
    harvests: [],
    maintenance: [],
    irrigations: [],
    soilTests: [],
    grazings: [],
    vetTreatments: [],
    tickets: [],
    fuelTickets: [],
    chemicalTickets: [],
    fertiliserTickets: [],
    waterTickets: [],
    contracts: [],
    timesheets: [],
    timesheetDetails: [],
    newTimesheets: [],
    timesheetSubmissions: [],
    qualifications: [],
    inductions: [],
    toolboxMeetings: [],
    workTypes: [],
    checklistTemplates: [],
    inductionTemplates: [],
    farms: [],
    paddockMaps: [],
    chemicalDepots: [],
    waterStorages: [],
    rainGauges: [],
    rainRecords: [],
    cultivars: [],
    livestock: [],
    vetProducts: [],
    equipment: [],
    chemicals: [],
    fertilisers: [],
    users: [],
    contractors: [],
    nozzles: [],
    waterLicences: [],
    costCodes: [],
    calendarItems: []
};
