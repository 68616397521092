import Vue from 'vue';
import Router from 'vue-router';
import Front from './views/Front.vue';
import store from '@/store';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '*',
            redirect: '/front'
        },
        {
            path: '/',
            redirect: '/front'
        },
        {
            path: '/front',
            name: 'FrontPage',
            component: Front
        },
        {
            path: '/home',
            name: 'HomePage',
            component: () => import('./views/Home.vue'),
            meta: {
                authRequired: true
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.getters.isAuthenticated) {
            next({
                path: '/front'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
