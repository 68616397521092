<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
        <v-card>
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="message != null && typeof message == 'string'" class="pa-4">
                <div v-for="(text, index) in message.split('\n')" :key="index">
                    {{ text }}
                </div>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn v-if="options.disagreeAvailable" :color="options.disagreeColour" text @click.native="cancel">
                    {{ options.disagree }}
                </v-btn>
                <v-spacer />
                <v-btn :color="options.agreeColour" text @click.native="agree">{{ options.agree }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: '',
        title: null,
        options: {
            color: 'primary',
            width: 290,
            zIndex: 200,
            agree: 'Yes',
            disagree: 'Cancel',
            disagreeAvailable: true,
            agreeColour: 'red darken-4',
            disagreeColour: 'grey darken-2'
        }
    }),

    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            this.resolve(true);
            this.dialog = false;
        },

        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
};
</script>
