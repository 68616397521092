//
//  common.js
//  croppa-web
//
//  Created by Matthew Higham on 27/08/2018
//  Copyright © 2018 CroppaCo Pty. Ltd. All rights reserved.
//

export function snapshotToArray(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        var item = snapshot[index];
        item.key = index;

        returnArr.push(item);
    }

    return returnArr;
}

export function snapshotToArraySingle(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        // Init
        var output = {
            key: index,
            index: returnArr.length,
            name: '',
            archived: false
        }

        if (snapshot[index].name) {
            // Set name
            output.name = snapshot[index].name;

            // Address?
            if (snapshot[index].streetLine1) {
                output.streetLine1 = snapshot[index].streetLine1;
            }
            if (snapshot[index].streetLine2) {
                output.streetLine2 = snapshot[index].streetLine2;
            }
            if (snapshot[index].suburb) {
                output.suburb = snapshot[index].suburb;
            }
            if (snapshot[index].state) {
                output.state = snapshot[index].state;
            }
            if (snapshot[index].postcode) {
                output.postcode = snapshot[index].postcode;
            }
            if (snapshot[index].country) {
                output.country = snapshot[index].country;
            }
        } 
        else if (snapshot[index].firstName) {
            // Set name
            output.name = snapshot[index].firstName + ' ' + snapshot[index].lastName;
        }

        // Archived?
        if (snapshot[index].archived) {
            output.archived = snapshot[index].archived;
        }

        // Push
        returnArr.push(output);
    }

    return returnArr;
}

export function snapshotToList(snapshot) {
    var returnArr = [];

    for (let index in snapshot) {
        let item = {key: index, name: snapshot[index]};
        returnArr.push(item);
    }

    return returnArr;
}

export function convertDate(timestamp) {
    // Months array
    const MONTHS_ARR = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Convert timestamp to milliseconds
    var date = new Date(timestamp * 1000);

    // Year
    var year = date.getFullYear();

    // Month
    var month = MONTHS_ARR[date.getMonth()];

    // Day
    var day = date.getDate();

    // Hours
    var hours = date.getHours();

    // Minutes
    var minutes = '0' + date.getMinutes();

    // Seconds
    var seconds = '0' + date.getSeconds();

    // Display date time in MM-dd-yyyy h:m:s format
    return day + ' ' + month + ' ' + year + ' ' + hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2);
}

export function buildTicketIdentifier(ticket, type, number, prefix = '') {
    // Valid number?
    if (number && number > 0) {
        let IdTypes = [
            ['GRI-', 'GRO-', 'GRS-', 'GRT-'],
            ['CHI-', 'CHO-', 'CHS-', 'CHT-'],
            ['FEI-', 'FEO-', 'FES-', 'FET-'],
            ['FUI-', 'FUO-', 'FUS-', 'FUT-'],
            ['WTI-', 'WTO-', 'WTS-', 'WTT-']
        ];
        if (number > 9999) {
            return prefix + IdTypes[ticket][type] + `${number}`;
        }
        return prefix + IdTypes[ticket][type] + `0000${number}`.slice(-4);
    }
    return '';
}

export function floatToString2DecPl(value) {
    // Round and remove superfluous 0's
    if (value) {
        var newValue = Math.round((value + Number.EPSILON) * 100) / 100;
        return newValue.toString();
    }
    return '0';
}

export function float2DecPl(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function convertPaddockArea(fromUnit, toUnit, area) {
    // Hectares
    if (toUnit == 0) {
        return convertToHa(fromUnit, area);
    }
    // Acres
    if (toUnit == 1) {
        return convertToAc(fromUnit, area);
    }
    // Square Metres
    if (toUnit == 2) {
        return convertToSqm(fromUnit, area);
    }
    // Must be Square Ft
    return convertToSqft(fromUnit, area);
}

export function convertToHa(fromUnit, value) {
    // Hectares
    if (fromUnit == 0) {
        return value;
    }
    // Acres
    if (fromUnit == 1) {
        return value * 0.404686;
    }
    // Square Metres
    if (fromUnit == 2) {
        return value / 10000;
    }
    // Must be Square Ft
    return value / 107639;
}

export function convertToAc(fromUnit, value) {
    // Hectares
    if (fromUnit == 0) {
        return value * 2.47105;
    }
    // Acres
    if (fromUnit == 1) {
        return value;
    }
    // Square Metres
    if (fromUnit == 2) {
        return value / 4047;
    }
    // Must be Square Ft
    return value / 43560;
}

export function convertToSqm(fromUnit, value) {
    // Hectares
    if (fromUnit == 0) {
        return value * 10000;
    }
    // Acres
    if (fromUnit == 1) {
        return value * 4046.86;
    }
    // Square Metres
    if (fromUnit == 2) {
        return value;
    }
    // Must be Square Ft
    return value / 10.764;
}

export function convertToSqft(fromUnit, value) {
    // Hectares
    if (fromUnit == 0) {
        return value * 107639;
    }
    // Acres
    if (fromUnit == 1) {
        return value * 43560;
    }
    // Square Metres
    if (fromUnit == 2) {
        return value * 10.764;
    }
    // Must be Square Ft
    return value;
}

export function convertArea(value, rate, baseRate) {
    // Rate in Hectares?
    if (rate <= 5 || rate == 16) {
        return convertToHa(baseRate, value);
    }

    // Rate must be in Acres
    return convertToAc(baseRate, value);
}

export function tankAmountConvertable(fromUnits, toUnits) {
    // Volume?
    if (fromUnits == 0 || fromUnits == 1 || fromUnits == 6 || fromUnits == 7 || fromUnits == 8 || fromUnits == 9) {
        // Volume?
        if (toUnits == 0 || toUnits == 1 || toUnits == 6 || toUnits == 7 || toUnits == 8 || toUnits == 9) {
            return true;
        }
        return false;
    }

    // Mass?
    if (fromUnits == 2 || fromUnits == 3 || fromUnits == 4 || fromUnits == 5 || fromUnits == 10) {
        // Mass?
        if (toUnits == 2 || toUnits == 3 || toUnits == 4 || toUnits == 5 || toUnits == 10) {
            return true;
        }
        return false;
    }

    // Default
    return false;
}

export function computeAmount(areaToCover, rate, rateUnits, paddockUnits) {
    // Must be area based unit
    let area = convertArea(areaToCover, rateUnits, paddockUnits);
    let areaAmount = area * rate;

    // mL/ha
    if (rateUnits == 0) {
        // Convert to litres if >= 1000mL
        if (areaAmount >= 1000) {
            return floatToString2DecPl(areaAmount / 1000) + ' l';
        }
        return floatToString2DecPl(areaAmount) + ' ml';
    }
    // L/ha
    if (rateUnits == 1) {
        return floatToString2DecPl(areaAmount) + ' l';
    }
    // g/ha or g/ac
    if (rateUnits == 2 || rateUnits == 12) {
        // Convert to tonnes if >= 1000kg
        if (areaAmount >= 1000000) {
            return floatToString2DecPl(areaAmount / 1000000) + ' t';
        }
        // Convert to kilograms if >= 1000g
        if (areaAmount >= 1000) {
            return floatToString2DecPl(areaAmount / 1000) + ' kg';
        }
        return floatToString2DecPl(areaAmount) + ' g';
    }
    // kg/ha
    if (rateUnits == 3) {
        // Convert to tonnes if >= 1000kg
        if (areaAmount >= 1000) {
            return floatToString2DecPl(areaAmount / 1000) + ' t';
        }
        return floatToString2DecPl(areaAmount) + ' kg';
    }
    // oz/ha or oz/ac
    if (rateUnits == 4 || rateUnits == 13) {
        // Convert to pounds if >= 16oz
        if (areaAmount >= 16) {
            return floatToString2DecPl(areaAmount / 16) + ' lb';
        }
        return floatToString2DecPl(areaAmount) + ' oz';
    }
    // lb/ha or lb/ac
    if (rateUnits == 5 || rateUnits == 14) {
        return floatToString2DecPl(areaAmount) + ' lb';
    }
    // floz/ac
    if (rateUnits == 9) {
        // Convert to gallons if >= 128floz
        if (areaAmount >= 128) {
            return floatToString2DecPl(areaAmount / 128) + ' gal';
        }
        return floatToString2DecPl(areaAmount) + ' floz';
    }
    // qt/ac
    if (rateUnits == 10) {
        // Convert to gallons if >= 4qt
        if (areaAmount >= 4) {
            return floatToString2DecPl(areaAmount / 4) + ' gal';
        }
        return floatToString2DecPl(areaAmount) + ' qt';
    }
    // pt/ac
    if (rateUnits == 11) {
        // Convert to gallons if >= 8pt
        if (areaAmount >= 8) {
            return floatToString2DecPl(areaAmount / 8) + ' gal';
        }
        return floatToString2DecPl(areaAmount) + ' pt';
    }
    // t/ha
    if (rateUnits == 16) {
        return floatToString2DecPl(areaAmount) + ' t';
    }
    // gal/ac
    if (rateUnits == 19) {
        return floatToString2DecPl(areaAmount) + ' gal';
    }
    return '';
}

export function convertVolume(baseRate, value, rate) {
    // ml/100L?
    if (baseRate == 6) {
        // Gallons?
        if (rate == 1) {
            return this.gallonsToLitres(value) / 100;
        }

        // Litres
        return value / 100;
    }

    // Must be L/1000L or kg/1000L
    // Gallons?
    if (rate == 1) {
        return this.gallonsToLitres(value) / 1000;
    }
    // Litres
    return value / 1000;
}

export function convertTankAmount(inValue, fromUnits, toUnits) {
    // If nothing, return nothing
    if (inValue == 0) {
        return 0;
    }

    // Convert
    let value = parseFloat(inValue);

    // Valid?
    if (isNaN(value)) {
        return 0;
    }

    // Convert units
    switch (fromUnits) {
        case 0:
            // Litres
            switch (toUnits) {
                case 0:
                    // Litres
                    return value;
                case 1:
                    // Gallons
                    return litresToGallons(value);
                case 6:
                    // Millilitres
                    return value * 1000;
                case 7:
                    // Fluid Ounces
                    return litresToGallons(value) * 128;
                case 8:
                    // Quarts
                    return litresToGallons(value) * 4;
                case 9:
                    // Pints
                    return litresToGallons(value) * 8;
                default:
                    return 0.0;
            }
        case 1:
            // Gallons
            switch (toUnits) {
                case 0:
                    // Litres
                    return gallonsToLitres(value);
                case 1:
                    // Gallons
                    return value;
                case 6:
                    // Millilitres
                    return gallonsToLitres(value) * 1000;
                case 7:
                    // Fluid Ounces
                    return value * 128;
                case 8:
                    // Quarts
                    return value * 4;
                case 9:
                    // Pints
                    return value * 8;
                default:
                    return 0.0;
            }
        case 2:
            // Grams
            switch (toUnits) {
                case 2:
                    // Grams
                    return value;
                case 3:
                    // Kilograms
                    return value / 1000;
                case 4:
                    // Ounces
                    return kilosToPounds(value / 1000) * 16;
                case 5:
                    // Pounds
                    return kilosToPounds(value / 1000);
                case 10:
                    // Tonnes
                    return value / 1000000;
                default:
                    return 0.0;
            }
        case 3:
            // Kilograms
            switch (toUnits) {
                case 2:
                    // Grams
                    return value * 1000;
                case 3:
                    // Kilograms
                    return value;
                case 4:
                    // Ounces
                    return kilosToPounds(value) * 16;
                case 5:
                    // Pounds
                    return kilosToPounds(value);
                case 10:
                    // Tonnes
                    return value / 1000;
                default:
                    return 0.0;
            }
        case 4:
            // Ounces
            switch (toUnits) {
                case 2:
                    // Grams
                    return poundsToKilos(value / 16) * 1000;
                case 3:
                    // Kilograms
                    return poundsToKilos(value / 16);
                case 4:
                    // Ounces
                    return value;
                case 5:
                    // Pounds
                    return value / 16;
                case 10:
                    // Tonnes
                    return poundsToKilos(value / 16) / 1000;
                default:
                    return 0.0;
            }
        case 5:
            // Pounds
            switch (toUnits) {
                case 2:
                    // Grams
                    return poundsToKilos(value) * 1000;
                case 3:
                    // Kilograms
                    return poundsToKilos(value);
                case 4:
                    // Ounces
                    return value * 16;
                case 5:
                    // Pounds
                    return value;
                case 10:
                    // Tonnes
                    return poundsToKilos(value) / 1000;
                default:
                    return 0.0;
            }
        case 6:
            // Millilitres
            switch (toUnits) {
                case 0:
                    // Litres
                    return value / 1000;
                case 1:
                    // Gallons
                    return litresToGallons(value / 1000);
                case 6:
                    // Millilitres
                    return value;
                case 7:
                    // Fluid Ounces
                    return litresToGallons(value / 1000) * 128;
                case 8:
                    // Quarts
                    return litresToGallons(value / 1000) * 4;
                case 9:
                    // Pints
                    return litresToGallons(value / 1000) * 8;
                default:
                    return 0.0;
            }
        case 7:
            // Fluid Ounces
            switch (toUnits) {
                case 0:
                    // Litres
                    return gallonsToLitres(value / 128);
                case 1:
                    // Gallons
                    return value / 128;
                case 6:
                    // Millilitres
                    return gallonsToLitres(value / 128) * 1000;
                case 7:
                    // Fluid Ounces
                    return value;
                case 8:
                    // Quarts
                    return value / 32;
                case 9:
                    // Pints
                    return value / 16;
                default:
                    return 0.0;
            }
        case 8:
            // Quarts
            switch (toUnits) {
                case 0:
                    // Litres
                    return gallonsToLitres(value / 4);
                case 1:
                    // Gallons
                    return value / 4;
                case 6:
                    // Millilitres
                    return gallonsToLitres(value / 4) * 1000;
                case 7:
                    // Fluid Ounces
                    return value * 32;
                case 8:
                    // Quarts
                    return value;
                case 9:
                    // Pints
                    return value * 2;
                default:
                    return 0.0;
            }
        case 9:
            // Pints
            switch (toUnits) {
                case 0:
                    // Litres
                    return gallonsToLitres(value / 8);
                case 1:
                    // Gallons
                    return value / 8;
                case 6:
                    // Millilitres
                    return gallonsToLitres(value / 8) * 1000;
                case 7:
                    // Fluid Ounces
                    return value * 16;
                case 8:
                    // Quarts
                    return value / 2;
                case 9:
                    // Pints
                    return value;
                default:
                    return 0.0;
            }
        case 10:
            // Tonnes
            switch (toUnits) {
                case 2:
                    // Grams
                    return value * 1000000;
                case 3:
                    // Kilograms
                    return value * 1000;
                case 4:
                    // Ounces
                    return kilosToPounds(value * 1000) * 16;
                case 5:
                    // Pounds
                    return kilosToPounds(value * 1000);
                case 10:
                    // Tonnes
                    return value;
                default:
                    return 0.0;
            }
        default:
            return 0.0;
    }
}

export function convertTankAmountString(value, fromUnits, toUnits) {
    // If nothing, return nothing
    if (value == 0) {
        return 0;
    }

    const TankString = ['L', 'gal', 'g', 'kg', 'oz', 'lb', 'mL', 'floz', 'qt', 'pt', 't'];
    let fromIndex = TankString.indexOf(fromUnits);
    let toIndex = TankString.indexOf(toUnits);

    return convertTankAmount(value, fromIndex, toIndex);
}

function litresToGallons(value) {
    return value * 0.2641720524;
}

function gallonsToLitres(value) {
    return value * 3.785411784;
}

function kilosToPounds(value) {
    return value * 2.2046226218;
}

function poundsToKilos(value) {
    return value * 0.45359237;
}

export function convertHeightUnits(value, fromUnits, toUnits) {
    // No value?
    if (value == 0) {
        return 0.0;
    }

    // Same Unit?
    if (fromUnits == toUnits) {
        return value;
    }

    // Convert units
    switch (fromUnits) {
        // Millimetres
        case 0:
            switch (toUnits) {
                // Centimetres
                case 1:
                    return (value / 10);
                // Metres
                case 2:
                    return (value / 1000);
                // Inches
                case 3:
                    return metresToYards(value / 1000) * 36;
                // Feet
                case 4:
                    return metresToYards(value / 1000) * 3;
                // Yards
                case 5:
                    return metresToYards(value / 1000);
                // Not found
                default: 
                    return 0.0;
            }
        // Centimetres
        case 1:
            switch (toUnits) {
                // Millimetres
                case 0:
                    return (value * 10);
                // Metres
                case 2:
                    return (value / 100);
                // Inches
                case 3:
                    return metresToYards(value / 100) * 36;
                // Feet
                case 4:
                    return metresToYards(value / 100) * 3;
                // Yards
                case 5:
                    return metresToYards(value / 100);
                // Not found
                default: 
                    return 0.0;
            }
        // Metres
        case 2:
            switch (toUnits) {
                // Millimetres
                case 0:
                    return (value * 1000);
                // Centimetres
                case 1:
                    return (value * 100);
                // Inches
                case 3:
                    return metresToYards(value) * 36;
                // Feet
                case 4:
                    return metresToYards(value) * 3;
                // Yards
                case 5:
                    return metresToYards(value);
                // Not found
                default: 
                    return 0.0;
            }
        // Inches
        case 3:
            switch (toUnits) {
                // Millimetres
                case 0:
                    return yardsToMetres(value * 36) * 1000;
                // Centimetres
                case 1:
                    return yardsToMetres(value * 36) * 100;
                // Metres
                case 2:
                    return yardsToMetres(value * 36);
                // Feet
                case 4:
                    return value / 12;
                // Yards
                case 5:
                    return value / 36;
                // Not found
                default: 
                    return 0.0;
            }
        // Feet
        case 4:
            switch (toUnits) {
                // Millimetres
                case 0:
                    return yardsToMetres(value * 3) * 1000;
                // Centimetres
                case 1:
                    return yardsToMetres(value * 3) * 100;
                // Metres
                case 2:
                    return yardsToMetres(value * 3);
                // Inches
                case 3:
                    return value * 12;
                // Yards
                case 5:
                    return value / 3;
                // Not found
                default: 
                    return 0.0;
            }
        // Yards
        case 5:
            switch (toUnits) {
                // Millimetres
                case 0:
                    return yardsToMetres(value) * 1000;
                // Centimetres
                case 1:
                    return yardsToMetres(value) * 100;
                // Metres
                case 2:
                    return yardsToMetres(value);
                // Inches
                case 3:
                    return value * 36;
                // Feet
                case 4:
                    return value * 3;
                // Not found
                default: 
                    return 0.0;
            }
        default: 
            return 0.0;
    }
}

function metresToYards(value) {
    return value * 1.0936;
}

function yardsToMetres(value) {
    return value * 0.9144;
}

export function convertWaterUnits(value, fromUnits, toUnits) {
    // No value?
    if (value == 0) {
        return 0.0;
    }

    // Same Unit?
    if (fromUnits == toUnits) {
        return value;
    }

    // Convert units
    switch (fromUnits) {
        // Litres
        case 0:
            switch (toUnits) {
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return value / 1000;
                // Megalitres
                case 2: 
                    return value / 1000000;
                // Gallons
                case 4:
                    return litresToGallons(value);
                // Cubic Feet
                case 5:
                    return value * 0.0353146667;
                // Cubic Yards
                case 6:
                    return value * 0.0013079506;
                default:
                    return 0.0;
            }
        // Kilolitres, Cubic Metres
        case 1:
        case 3:
            switch (toUnits) {
                // Litres
                case 0: 
                    return value * 1000;
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return value;
                // Megalitres
                case 2: 
                    return value / 1000;
                // Gallons
                case 4:
                    return litresToGallons(value * 1000);
                // Cubic Feet
                case 5:
                    return value * 35.3146667;
                // Cubic Yards
                case 6:
                    return value * 1.3079506;
                default:
                    return 0.0;
            }
        // Megalitres
        case 2:
            switch (toUnits) {
                // Litres
                case 0: 
                    return value * 1000000;
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return value * 1000;
                // Gallons
                case 4:
                    return litresToGallons(value * 1000000);
                // Cubic Feet
                case 5:
                    return value * 35314.6667;
                // Cubic Yards
                case 6:
                    return value * 1307.9506;
                default:
                    return 0.0;
            }
        // Gallons
        case 4:
            switch (toUnits) {
                // Litres
                case 0: 
                    return gallonsToLitres(value);
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return gallonsToLitres(value) * 1000;
                // Megalitres
                case 2: 
                    return gallonsToLitres(value) * 1000000;
                // Cubic Feet
                case 5:
                    return value * 0.1336806;
                // Cubic Yards
                case 6:
                    return value * 0.0049511;
                default:
                    return 0.0;
            }
        // Cubic Feet
        case 5:
            switch (toUnits) {
                // Litres
                case 0: 
                    return value * 28.3168466;
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return value * 0.0283168466;
                // Megalitres
                case 2: 
                    return value * 0.0000283168466;
                // Gallons
                case 4:
                    return value * 7.4805195;
                // Cubic Yards
                case 6:
                    return value / 27;
                default:
                    return 0.0;
            }
        // Cubic Yards
        case 6:
            switch (toUnits) {
                // Litres
                case 0: 
                    return value * 764.5549;
                // Kilolitres, Cubic Metres
                case 1:
                case 3:
                    return value * 0.7645549;
                // Megalitres
                case 2: 
                    return value * 0.0007645549;
                // Gallons
                case 4:
                    return value * 201.974026;
                // Cubic Feet
                case 5:
                    return value * 27;
                default:
                    return 0.0;
            }
        default:
            return 0.0;
    }
}
