//
//  index.js
//  croppa-web
//
//  Created by Matthew Higham on 11/08/2019
//  Copyright © 2019 CroppaCo Pty. Ltd. All rights reserved.
//

// Firebase configuration
import firebase from '@firebase/app';
import "firebase/storage";

// Configuration and initialisation
export const firebaseConfig = {
    apiKey: 'AIzaSyC9G5ooMsuaD6NhNZRsll_aM7OAZWfgtzQ',
    authDomain: 'croppa.firebaseapp.com',
    databaseURL: 'https://croppa.firebaseio.com',
    projectId: 'project-1789770584308915398',
    storageBucket: 'project-1789770584308915398.appspot.com',
    messagingSenderId: '838773307942',
    appId: '1:838773307942:web:1314d4dcf6fa54e0',
    measurementId: 'G-LPQWYXGXR8'
};
firebase.initializeApp(firebaseConfig);

export const fbStorage = firebase.storage();
