<!--
  //  Login.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 19/08/2018
  //  Copyright © 2018 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <v-row no-gutters class="login">
        <v-container fluid fill-height style="max-height: 100vh">
            <v-row justify="center" align="center" pa-5>
                <v-card width="500px" class="mx-a" raised style="background-color: #dedede">
                    <v-card-title class="justify-center">
                        <v-container fill-height fluid>
                            <v-row no-gutters align="center" justify="center">
                                <img style="margin-bottom: 20px" width="400px" src="@/assets/logo-colour.svg" />
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                label="Email"
                                v-model="email"
                                :append-icon="mdiEmail"
                                solo
                                color="#E05317"
                                background-color="#E9E9E9"
                                @keyup.enter="$refs.pwd.focus"
                                autocomplete="username"
                                required
                                style="margin: 0px 20px"
                            />
                            <v-text-field
                                ref="pwd"
                                name="password"
                                label="Password"
                                v-model="password"
                                :append-icon="showPwd ? mdiEyeOff : mdiEye"
                                :type="showPwd ? 'text' : 'password'"
                                solo
                                color="#E05317"
                                background-color="#E9E9E9"
                                @click:append="showPwd = !showPwd"
                                @keyup.enter="login"
                                autocomplete="current-password"
                                required
                                style="margin: 0px 20px"
                            />

                            <!-- Forgot Password -->
                            <v-btn class="mt-n4" right absolute x-small text color="info" @click="forgotPassword">
                                Forgot Password?
                            </v-btn>
                        </v-form>
                    </v-card-text>
                    <v-card-actions />
                    <v-card-actions>
                        <v-btn class="mx-auto" @click="login" :loading="isRunning" dark min-width="180" color="#E05317"
                            >Login</v-btn
                        >
                    </v-card-actions>
                    <v-card-actions />
                    <v-card-actions>
                        <span class="mx-auto">
                            Don't have an account?
                            <v-btn text color="info" @click="signUp">Sign Up</v-btn>
                        </span>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-btn class="mx-auto" absolute top right href="https://grain.croppaco.com" target="_blank" dark min-width="180" color="#E05317" pt-5>
                Grain Console
            </v-btn>
        </v-container>
    </v-row>
</template>

<script>
import { mdiEmail, mdiEye, mdiEyeOff } from '@mdi/js';

export default {
    name: 'LoginPage',
    data: function () {
        return {
            mdiEmail,
            mdiEye,
            mdiEyeOff,
            email: '',
            password: '',
            showPwd: false
        };
    },
    computed: {
        isRunning() {
            return this.$store.state.firebaseWorking;
        }
    },
    methods: {
        login: function () {
            if (this.isRunning == false) {
                this.$store.dispatch('clearNewSub');
                this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password
                });
            }
        },
        signUp: function () {
            if (this.isRunning == false) {
                this.$parent.showLogin = 0xa5;
            }
        },
        forgotPassword: function () {
            if (this.isRunning == false) {
                this.$parent.showLogin = 0xaa;
            }
        }
    }
};
</script>

<style scoped>
.login {
    background: url('https://images.unsplash.com/photo-1633554587766-ffc8151e31c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80');
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
