<template>
    <v-app id="app">
        <app-navigation v-if="authenticated"></app-navigation>

        <v-main transition="slide-x-transition">
            <router-view></router-view>
        </v-main>
        
        <v-footer color="#DEDEDE" height="auto" style="margin: auto 0 0 0">
            <div v-if="!authenticated">
                <v-btn small href="https://croppaco.com/farm-simple-terms-of-use" text target="_blank" class="mr-1">Terms of Use</v-btn>|
                <v-btn small href="https://croppaco.com/farm-simple-privacy-policy" text target="_blank" class="mr-1">Privacy Policy</v-btn>
            </div>
            <v-spacer />
            <div style="margin-right: 20px">
                <v-btn small icon href="https://croppaco.com" target="_blank" class="mr-1"
                    ><v-icon>{{ mdiWeb }}</v-icon></v-btn
                >
                <v-btn small icon href="https://facebook.com/croppaco" target="_blank" class="mr-1"
                    ><v-icon>{{ mdiFacebook }}</v-icon></v-btn
                >
                <v-btn small icon href="https://twitter.com/croppaco" target="_blank" class="mr-1"
                    ><v-icon>{{ mdiTwitter }}</v-icon></v-btn
                >
                <v-btn
                    small
                    icon
                    href="https://www.youtube.com/channel/UCg-Pu877VEhoS9As-SOC3rw"
                    target="_blank"
                    class="mr-1"
                    ><v-icon>{{ mdiYoutube }}</v-icon></v-btn
                >
                <span style="margin-left: 10px; color: #4f4f4f">
                    &copy; {{ new Date().getFullYear() }} <strong>CroppaCo</strong>
                </span>
            </div>
        </v-footer>
        <confirm-component ref="confirm"></confirm-component>
    </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import { mdiWeb, mdiFacebook, mdiTwitter, mdiYoutube } from '@mdi/js';

export default {
    name: 'App',
    data: function () {
        return {
            mdiWeb,
            mdiFacebook,
            mdiTwitter,
            mdiYoutube
        };
    },
    components: {
        AppNavigation
    },
    computed: {
        authenticated() {
            return this.$store.getters.isAuthenticated;
        }
    },
    mounted() {
        this.$root.$confirm = this.$refs.confirm.open;
    }
};
</script>
